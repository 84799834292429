/**
 * NavDrawer: lit-element implementation of MDCDrawer component
 *  customized as common navigation menu for site
 * @license MIT
 * Copyright 2023 Four Mortals Productions, Inc.
 * @version 24.1.0
 * Module history:
 *    1.0.0 - Initial build
 *   13.0.0 - @material 13.0, lit 2.0
 *   14.4.5 - Extend FMPElement
 *          - Modify for theme/5.1
 *   24.1.0 - Use Material Webv (m3) component
 * @since September 2020
 * @author Earl Rogers
 */

'use strict';

import {html, LitElement, nothing, unsafeCSS} from 'lit';
import {classMap} from 'lit/directives/class-map.js';
import style from './_index.scss';

export class NavDrawer extends LitElement {
  static get changedEvent() {
    return 'navigation-drawer-changed';
  }
  /**
   * Set CSS for this component
   * @return {css}
   */
  static get styles() {
    return unsafeCSS(style);
  }
  /**
   * Define properties for this component
   * @return {Object}
   */
  static get properties() {
    return {
      opened: {type: Boolean},
      currentItem: {type: Object, attribute: false},
    };
  }
  constructor() {
    super();
    this.currentItem = undefined;
    this.opened = false;
  }


  firstUpdated(changedProperties) {
    document.addEventListener('TopAppBar:nav', () => {
      this.opened = true;
      if (this.opened) {
        const slot = this.shadowRoot.querySelector('slot');
        const slotEls = slot.assignedElements({flatten: true});
        const list = slotEls[0];
        for (const item of list.children) {
          if (item.href && item.href === location.hash) {
            item.disabled = true;
          } else {
            if (item.disabled) {
              item.disabled = false;
            }
          }
        }
      }
    });
    window.addEventListener('hashchange', (event) => {
      this.opened = false;
    });
    super.firstUpdated(changedProperties);
  }


  getRenderClasses() {
    return classMap({
      'nav--opened': this.opened,
    });
  }

  getScrimClasses() {
    return classMap({
      'nav__scrim-visible': this.opened,
    });
  }

  handleKeyDown(event) {
    if (event.code === 'Escape') {
      this.opened = false;
    }
  }

  handleScrimClick() {
    this.opened = !this.opened;
  }

  render() {
    const ariaExpanded = this.opened ? 'true' : 'false';
    const ariaHidden = !this.opened ? 'true' : 'false';
    // Needed for closure conformance
    const {ariaLabel, ariaModal} = this;

    /* eslint-disable max-len, indent */
    return html`
    <div
        class="nav__scrim ${this.getScrimClasses()}"
        @click="${this.handleScrimClick}">
      </div>
    <nav class="nav ${this.getRenderClasses()}"
        aria-expanded=${ariaExpanded}
        aria-hidden=${ariaHidden}
        aria-label=${ariaLabel || nothing}
        aria-modal=${ariaModal || nothing}
        @keydown="${this.handleKeyDown}">
        <slot></slot>
        ${location.hash ? html`
          <md-list-item
            style="margin: -2rem 0 0 2rem" 
            href="#home">
            <md-icon slot="start">home</md-icon>
            Home
          </md-list-item>
        ` : nothing}
    </nav>
    <div class="mdc-drawer-scrim"></div>
    `;
    /* eslint-enable max-len */
  }

  updated(changedProperties) {
    if (changedProperties.has('opened')) {
        setTimeout(() => {
            this.dispatchEvent(new CustomEvent(this.constructor.changedEvent, {
                detail: {opened: this.opened},
                bubbles: true,
                composed: true,
            }));
        }, 250);
    }
  }
}
// Register the new element with the browser.
customElements.define('oc4c-nav-drawer', NavDrawer);
