/**
 * OC4CTopAppBar: top app barl
 * @license MIT
 * Copyright 2024 Four Mortals Productions, Inc.
 * @version 24.1.0
 * Module history:
 *   24.1.0 - Initial build replacing @oc4c/material components
 * @since April 2024
 * @author Earl Rogers
 */

'use strict';

import {html, LitElement, nothing, unsafeCSS} from 'lit';
// import {classMap} from 'lit/directives/class-map.js';
import style from './_index.scss';
/* eslint-disable no-unused-vars */
import {FMPScrollbarWidth_} from '@fourmortals/element/scrollbar.js';
// import {FMPNavDrawer} from '../nav-drawer/element.js';
// import {FMPCopyright} from '@fourmortals/theme/copyright.js';
import {FMPTouch} from '@fourmortals/touch';
/* eslint-enable no-unused-vars */
import {emit} from '@fourmortals/events';

export class TopAppBar extends LitElement {
  /**
   * Set CSS for this component
   * @return {css}
   */
  static get styles() {
    return unsafeCSS(style);
  }
  /**
   * Define properties for this component
   * @return {Object}
   */
  static get properties() {
    return {
      appBarHeight: {type: String, attribute: false},
      isDarkMode: {type: Boolean, attribute: false},
      useNavDrawer: {type: Boolean, attribute: false},
    };
  }

  constructor() {
    super();
    const theme = localStorage.getItem('oc4c-theme');
    if (theme) {
      this.isDarkMode = theme === 'dark';
    } else {
      this.isDarkMode = window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
    /* Already done in styles.js
    this.isDarkMode = window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;
    if (this.isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.add('light');
    }
    */
    this.navClickListener = false;
    this.mobileBreakpoint = 756.1;
    this.useNavDrawer =
      document.documentElement.offsetWidth < this.mobileBreakpoint;
  }

  activateNavBtnListener() {
    const menuBtn = this.shadowRoot.querySelector('#open-nav');
    if (menuBtn) {
      menuBtn.addEventListener('click', (event) => {
        emit('TopAppBar:nav', {});
      });
      this.navClickListener = true;
    }
  }

  async firstUpdated(changedProperties) {
    window.addEventListener('load', (event) => {
      const el = document.querySelector('body>main');
      if (el) {
        const compStyles = window.getComputedStyle(el);
        // eslint-disable-next-line eqeqeq
        if (compStyles.getPropertyValue('opacity') == 0) {
          const inviz = 'theme-invisible';
          const viz = 'theme-visible';
          if (el.classList.contains(inviz)) {
            el.classList.remove(inviz);
          } else {
            el.classList.add(viz);
          }
        }
      }
    }, {once: true});

    const nav = this.shadowRoot.querySelector('nav');
    this.appBarHeight = `${nav.offsetHeight}px`;

    const toggle = this.shadowRoot.querySelector('#mode-toggle');
    toggle.addEventListener('click', (e) => {
      const current = this.isDarkMode ? 'dark' : 'light';
      const tgt = current === 'light' ? 'dark' : 'light';
      this.isDarkMode = !this.isDarkMode;
      document.documentElement.classList.remove(current);
      document.documentElement.classList.add(tgt);
      localStorage.setItem('oc4c-theme', tgt);
    });

    const el = this.shadowRoot.querySelector('oc4c-logo');
    el.addEventListener('click', (event) => {
      if (location.pathname !== '/') {
        location.href = '/';
      } else {
        if (location.hash && location.hash !== '#home') {
          location.hash = '#home';
        }
      }
    });

    this.activateNavBtnListener();

    window.addEventListener('hashchange', (event) => {
      const els = [...this.shadowRoot.querySelectorAll('md-text-button')];
      for (const el of els) {
        if (el.href === location.hash) {
          el.disabled = true;
        } else {
          if (el.disabled) {
            el.disabled = false;
          }
        }
      }
      if (location.hash === '#home') {
        // eslint-disable-next-line max-len
        history.replaceState('', document.title, location.pathname + location.search);
      }
      this.requestUpdate();
    });

    const resizeObserver = new ResizeObserver((entries) => {
      const i = entries[0].target.offsetHeight;
      this.appBarHeight = `${i}px`;
      this.useNavDrawer =
        entries[0].target.offsetWidth < this.mobileBreakpoint;
      // this.requestUpdate();
    });
    resizeObserver.observe(nav);
    super.firstUpdated(changedProperties);
  }

  get nav() {
    return this.shadowRoot.querySelector('nav');
  }

  getIcons() {
    /*
        <md-icon-button id="instagram" title="instagram"
          href="https://www.instagram.com/onechoiceforchange/"
          target="blank"
          aria-label="Follow us on Instagram">
          <span class="fa-brands fa-instagram"></span>
        </md-icon-button>
        <md-icon-button href="#contact"
          aria-label="Contact us"
          title="Contact us">
          <md-icon>call</md-icon>
        </md-icon-button>
    */
    const txt = this.isDarkMode ? 'Dark mode' : 'Light mode';
    return html `
      <div class="top-app-bar__icons">
        <md-icon-button toggle ?selected="${this.isDarkMode}"
          id="mode-toggle" aria-label="Toggle light/dark mode"
          aria-label-selected="${txt}"
          title="Toggle light/dark mode">
        <md-icon slot="selected">dark_mode</md-icon>
        <md-icon>light_mode</md-icon>
      </md-icon-button>
      </div>
    `;
  }

  getLogo() {
    return html`
        <oc4c-logo></oc4c-logo>
      `;
  }

  getNavButtons() {
    return html`
      ${location.hash ? html`
        <md-icon-button @click="${this.goback}" style="margin-right: 0.5rem">
          <md-icon>arrow_back</md-icon>
        </md-icon-button>
      ` : nothing}
      <md-text-button href="#artists">Get Involved</md-text-button>
      <md-text-button href="#recipients">Recipients</md-text-button>
      <md-text-button
        href="https://www.instagram.com/onechoiceforchange/"
        target="blank"
        aria-label="Follow us on Instagram">Follow us
      </md-text-button>  
      <md-text-button href="#contact">Contact</md-text-button>
      ${location.hash ? html`
        <md-text-button href="#home">Home</md-text-button>
      ` : nothing}
    `;
  }

  goback() {
    history.back();
  }

  render() {
    /* eslint-disable max-len */
    return html`
      <nav part="nav" class="top-app-bar top-app-bar--fixed">
        <div class="top-app-bar__row">
          <section class="top-app-bar__section top-app-bar__section--align-start">
            ${this.getLogo()}
          </section>
          <section class="top-app-bar__section top-app-bar__section--align-center">
            <slot name="center"></slot>
          </section>
          <section class="top-app-bar__section top-app-bar__section--align-end">
            ${!this.useNavDrawer ? this.getNavButtons() : nothing}
            ${this.getIcons()}
            ${this.useNavDrawer ?
              html`
                <md-icon-button id="open-nav">
                  <md-icon>menu</md-icon>
                </md-icon-button>
              ` : nothing}
          </section>
        </div>
      </nav>
    `;
    /* eslint-enable max-len */
  }

  updated(changedProperties) {
    if (changedProperties.has('appBarHeight')) {
      document.documentElement.style
          .setProperty('--app-bar-height', this.appBarHeight);
    }
    if (changedProperties.has('useNavDrawer')) {
      this.activateNavBtnListener();
    }
  }
}
// Register the new element with the browser.
customElements.define('oc4c-top-app-bar', TopAppBar);
